import React, { memo, useCallback, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { SelectedItem } from 'Containers/RocketScope/types';
import { TextBox } from 'Components/TextBox';
import classes from './scopeOfWorkAdjust.module.css';

interface Props {
  selectedScopeItem: SelectedItem | undefined;
  setItemQuantity: (item: SelectedItem, quantity: number) => void;
}

const ScopeOfWorkAdjust = ({ selectedScopeItem, setItemQuantity }: Props) => {
  const [quantityInvalid, setQuantityInvalid] = useState(false);
  const [quantity, setQuantity] = useState(selectedScopeItem ? selectedScopeItem.quantity.toString() : '0');

  const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const onChangeQuantity = useCallback(
    (e: any) => {
      const value = parseFloat(e.target.value);
      setQuantity(e.target.value);
      if (value <= 9999) {
        setQuantityInvalid(false);
        setItemQuantity(selectedScopeItem, value);
      } else {
        setQuantityInvalid(true);
      }
    },
    [selectedScopeItem]
  );

  useEffect(() => {
    if (selectedScopeItem) {
      setQuantity(selectedScopeItem.quantity.toString());
    }
  }, [selectedScopeItem]);

  return selectedScopeItem ? (
    <div>
      <div className={classes.containerRow}>
        <TextBox
          name="quantity"
          type="number"
          ariaLabel="unit quantity"
          className={`${classes.quantityInput}`}
          placeholder="0"
          max={9999}
          value={quantity}
          onChange={onChangeQuantity}
          autoComplete="off"
        />
        <div className={classes.unitLabel}>{selectedScopeItem.unit}</div>
        <div className={classes.costLabel}>
          <div className={classes.cost}>
            {selectedScopeItem.rate ? currencyFormat.format(parseFloat(selectedScopeItem.rate)) : ''}
          </div>
          <div className={classes.costLabel}>
            {selectedScopeItem.rate
              ? currencyFormat.format(parseFloat(selectedScopeItem.rate) * selectedScopeItem.quantity)
              : ''}
          </div>
        </div>
      </div>
      {quantityInvalid && <span className={classes.errorMessage}>Quantity must be less than or equal to 9999.</span>}
    </div>
  ) : null;
};

const ScopeOfWorkAdjustMemo = memo(ScopeOfWorkAdjust, areEqual);

export { ScopeOfWorkAdjustMemo as ScopeOfWorkAdjust };
