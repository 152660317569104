import { handleApiRequest } from 'Utils/handleApiRequest';
import { SelectedItem } from 'Containers/RocketScope/types';

export const CUSTOM_DAMAGED_MATERIAL_CREATED = 'CUSTOM_DAMAGED_MATERIAL_CREATED';
export const CUSTOM_DAMAGED_MATERIAL_UPDATED = 'CUSTOM_DAMAGED_MATERIAL_UPDATED';
export const CUSTOM_DAMAGED_MATERIAL_DELETED = 'CUSTOM_DAMAGED_MATERIAL_DELETED';
export const CREATE_CUSTOM_DAMAGED_MATERIAL_ERRORS = 'CREATE_CUSTOM_DAMAGED_MATERIAL_ERRORS';
export const EDIT_CUSTOM_DAMAGED_MATERIAL_ERRORS = 'EDIT_CUSTOM_DAMAGED_MATERIAL_ERRORS';
export const SET_PROJECT_SCOPE_TOTAL = 'SET_PROJECT_SCOPE_TOTAL';
export const SET_LOCATION_SCOPE_TOTAL = 'SET_LOCATION_SCOPE_TOTAL';
export const SET_ROOM_SCOPE_TOTAL = 'SET_ROOM_SCOPE_TOTAL';

interface ActionTypes {
  CUSTOM_DAMAGED_MATERIAL_CREATED: string;
  CUSTOM_DAMAGED_MATERIAL_UPDATED: string;
  CUSTOM_DAMAGED_MATERIAL_DELETED: string;
  CREATE_CUSTOM_DAMAGED_MATERIAL_ERRORS: string;
  EDIT_CUSTOM_DAMAGED_MATERIAL_ERRORS: string;
  SET_PROJECT_SCOPE_TOTAL: string;
  SET_LOCATION_SCOPE_TOTAL: string;
  SET_ROOM_SCOPE_TOTAL: string;
}

interface MessageAction {
  type: keyof ActionTypes;
  payload: any;
}

export type customDamagedMaterials = MessageAction;

export const setProjectScopeTotal = (projectId: number, total: number) => (dispatch) => {
  dispatch({
    type: SET_PROJECT_SCOPE_TOTAL,
    payload: { projectId, total },
  });
};

export const setLocationScopeTotal = (locationId: number, total: number) => (dispatch) => {
  dispatch({
    type: SET_LOCATION_SCOPE_TOTAL,
    payload: { locationId, total },
  });
};

export const setRoomScopeTotal = (roomId: number, total: number) => (dispatch) => {
  dispatch({
    type: SET_ROOM_SCOPE_TOTAL,
    payload: { roomId, total },
  });
};

/*
 * NON API THUNKS, NOT ASYNC
 * */

export const setCustomDamagedMaterialCreated = (value: boolean) => (dispatch) => {
  dispatch({
    type: CUSTOM_DAMAGED_MATERIAL_CREATED,
    payload: value,
  });
};

export const setCustomDamagedMaterialUpdated = (value: boolean) => (dispatch) => {
  dispatch({
    type: CUSTOM_DAMAGED_MATERIAL_UPDATED,
    payload: value,
  });
};

export const setCustomDamagedMaterialDeleted = (value: boolean) => (dispatch) => {
  dispatch({
    type: CUSTOM_DAMAGED_MATERIAL_DELETED,
    payload: value,
  });
};

/*
 * API THUNKS
 * */

/* eslint-disable */

export const addScopeSelectedItems =
  (roomId: number, projectId: number, locationId: number, selectedItems: SelectedItem[]) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const selectedItemsRequest = selectedItems.map((item) => {
      return {
        sheet_id: item.sheetId,
        description: item.description,
        quantity: item.quantity,
        category: item.category,
        code_part_1: item.codePart1,
        code_part_2: item.codePart2,
        unit: item.unit,
        rate: parseFloat(item.rate),
      };
    });
    const requestData = {
      selected_items: selectedItemsRequest,
    };

    const result = await handleApiRequest(dispatch, utils.Api.post(`/rooms/${roomId}/work-scope-items`, requestData));
    dispatch(getRoomScopeTotal(roomId, true));
    dispatch(getProjectScopeTotal(projectId, true));
    dispatch(getLocationScopeTotal(locationId, true));
    return result;
  };

export const syncScopeSelectedItems =
  (
    roomId: number,
    projectId: number,
    locationId: number,
    selectedItems: SelectedItem[],
    removedItems: SelectedItem[]
  ) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const selectedItemsRequest = selectedItems.map((item) => {
      return {
        id: item.id,
        sheet_id: item.sheetId,
        description: item.description,
        quantity: item.quantity,
        category: item.category,
        code_part_1: item.codePart1,
        code_part_2: item.codePart2,
        unit: item.unit,
        rate: parseFloat(item.rate),
      };
    });
    const removedItemsRequest = removedItems.map((item) => {
      return {
        id: item.id,
        sheet_id: item.sheetId,
        category: item.category,
        code_part_1: item.codePart1,
        code_part_2: item.codePart2,
      };
    });
    const requestData = {
      selected_items: selectedItemsRequest,
      removed_items: removedItemsRequest,
    };

    const result = await handleApiRequest(dispatch, utils.Api.post(`/rooms/${roomId}/work-scope-items`, requestData));
    dispatch(getRoomScopeTotal(roomId, true));
    dispatch(getProjectScopeTotal(projectId, true));
    dispatch(getLocationScopeTotal(locationId, true));
    return result;
  };

export const syncRoomDamagedMaterials =
  (roomId: number, requestData: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    await handleApiRequest(dispatch, utils.Api.post(`/rooms/${roomId}/damage-materials`, requestData));
  };

export const createCustomDamagedMaterial =
  (projectId: number, requestData: any, setFetching: any, setErrors: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    setFetching(true);

    const response = await handleApiRequest(
      dispatch,
      utils.Api.post(`projects/${projectId}/damage-materials`, requestData),
      '',
      '',
      setErrors
    );

    if (response?.data) {
      dispatch(setCustomDamagedMaterialCreated(true));
    }
    setFetching(false);
  };

export const updateCustomDamagedMaterial =
  (materialId: number, requestData: any, disableEditMode: any, setFetching: any, setErrors: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    setFetching(true);

    const response = await handleApiRequest(
      dispatch,
      utils.Api.put(`damage-materials/${materialId}`, requestData),
      '',
      '',
      setErrors
    );

    if (response?.data) {
      // allow re-fetching the material list
      dispatch(setCustomDamagedMaterialUpdated(true));
      // disable edit mode on individual container
      setTimeout(() => disableEditMode(), 1000);
    }

    setFetching(false);
  };

export const deleteCustomDamagedMaterial =
  (materialId: number, setErrors: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    const response = await handleApiRequest(
      dispatch,
      utils.Api.delete(`damage-materials/${materialId}`),
      '',
      '',
      setErrors
    );
    if (typeof response === 'string') {
      dispatch(setCustomDamagedMaterialDeleted(true));
    }
  };

export const updateMaterialScopeOfWork =
  (roomId: number, materialId: number, requestData: any) =>
  async (dispatch: any, _getState = null, utils: any) => {
    await handleApiRequest(dispatch, utils.Api.post(`/rooms/${roomId}/damage-materials/${materialId}`, requestData));
  };

export const getRoomScopeOfWorkItems =
  (roomId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.get(`/rooms/${roomId}/work-scope-items`));
  };

export const getRoomScopeTotal =
  (roomId: number, force: boolean = false) =>
  async (dispatch: any, getState: any, utils: any) => {
    const state = getState();
    const existingTotal = state.damagedMaterials.roomScopeTotals[roomId];

    if (!force && existingTotal !== undefined) {
      return existingTotal;
    }

    const response = await handleApiRequest(dispatch, utils.Api.get(`/rooms/${roomId}/work-scope-total`));
    if (response?.total) {
      dispatch(setRoomScopeTotal(roomId, response.total));
    }
    return response;
  };

export const getLocationScopeTotal =
  (locationId: number, force: boolean = false) =>
  async (dispatch: any, getState: any, utils: any) => {
    const state = getState();
    const existingTotal = state.damagedMaterials.locationScopeTotals[locationId];

    if (!force && existingTotal !== undefined) {
      return existingTotal;
    }

    const response = await handleApiRequest(dispatch, utils.Api.get(`/locations/${locationId}/work-scope-total`));
    if (response?.total) {
      dispatch(setLocationScopeTotal(locationId, response.total));
    }
    return response;
  };

export const getProjectScopeTotal =
  (projectId: number, force: boolean = false) =>
  async (dispatch: any, getState: any, utils: any) => {
    const state = getState();
    const existingTotal = state.damagedMaterials.projectScopeTotals[projectId];

    if (!force && existingTotal !== undefined) {
      return existingTotal;
    }

    const response = await handleApiRequest(dispatch, utils.Api.get(`/projects/${projectId}/work-scope-total`));
    if (response?.total) {
      dispatch(setProjectScopeTotal(projectId, response.total));
    }
    return response;
  };

export const getRoomDamageMaterials =
  (roomId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(
      dispatch,
      utils.Api.get(`/rooms/${roomId}/damage-materials`, {
        params: {
          include: 'damageType',
        },
      })
    );
  };
