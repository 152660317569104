import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { PurpleButton } from 'Components/Button';
import { DamagedMaterialsPlaceholder, DamagedMaterialsView, SheetNameMenu } from 'Components/RocketScan';
import { DamagedMaterialsModal, ScopeOfWorkAddModal } from 'Containers/RocketScan';
import { DamageType, ScopeSheet, SelectedItem } from 'Containers/RocketScope/types';
import { WorkScopeView } from 'Components/RocketScan/RoomsView/DamagedMaterialsView/WorkScopeView';
import { DamageTypesMenu } from 'Components/RocketScan/RoomsView/RoomContent';
import { userFeatureFlagsSelector } from 'Containers/User/selector';
import { useSelector } from 'react-redux';

import { useDamagedMaterialFunctions } from 'Context/RocketScan';
import { EditMode } from 'Context/RocketScan/DamagedMaterials/DamagedMaterialsContext';
import { ScopeOfWorkEditModal } from 'Containers/RocketScan/RoomsView/DamagedMaterials/ScopeOfWorkEditModal';
import classes from './damagedMaterials.module.css';

interface Props {
  roomId: number;
  damagesCount: number;
  editMode: EditMode;
  scopeSheets: ScopeSheet[];
  damageTypes: DamageType[];
  onClickEditButton: (e: any) => void;
  onClickAddButton: (e: any) => void;
}

const DamagedMaterials = ({
  roomId,
  damagesCount,
  editMode,
  scopeSheets,
  damageTypes,
  onClickEditButton,
  onClickAddButton,
}: Props) => {
  const { rocketScope } = useSelector(userFeatureFlagsSelector, areEqual);
  const { selectedItems } = useDamagedMaterialFunctions() as { selectedItems: SelectedItem[] };

  return (
    <div className={classes.damagedMaterialsHeader}>
      {rocketScope ? (
        <>
          <div className={classes.topRow}>
            <p className={classes.title}>Scope of Work Items</p>
            <div className={classes.buttonContainer}>
              <PurpleButton className={classes.editButton} onClick={onClickAddButton}>
                {editMode === EditMode.Adding ? 'Done' : 'Add'}
              </PurpleButton>
              {editMode === EditMode.NoEditing && selectedItems.length > 0 && (
                <PurpleButton className={classes.editButton} onClick={onClickEditButton}>
                  Edit
                </PurpleButton>
              )}
            </div>
          </div>
          <>
            {editMode === EditMode.Adding && (
              <div className={classes.content}>
                <div className="d-flex flex-column justify-content-start w-50">
                  <p className={classes.damagesCount}>{`${damagesCount} work items`}</p>
                  <p className={classes.smallTitles}>Select Scope Sheet</p>
                  <SheetNameMenu roomId={roomId} scopeSheets={scopeSheets} />
                </div>

                <div className={`d-flex flex-column justify-content-start w-50 ${classes.damagedMaterials}`}>
                  <DamagedMaterialsPlaceholder />
                  <ScopeOfWorkAddModal />
                </div>
              </div>
            )}
            {editMode === EditMode.Editing && <ScopeOfWorkEditModal />}
            {editMode === EditMode.NoEditing && (
              <div>
                <DamagedMaterialsView damageTypes={damageTypes} roomId={roomId} />
                <WorkScopeView />
              </div>
            )}
          </>
        </>
      ) : (
        <>
          <div className="d-flex w-100 flex-column">
            <p className={classes.title}>Damaged Materials</p>
            <PurpleButton className={classes.editButton} onClick={onClickEditButton}>
              {editMode === EditMode.Editing ? 'Done' : 'Edit'}
            </PurpleButton>
          </div>
          {editMode === EditMode.Editing ? (
            <div className={classes.content}>
              <div className={classes.contentOld}>
                <p className={classes.smallTitles}>Damage Types</p>
                <p className={classes.damagesCount}>{`${damagesCount} damages selected`}</p>
                <DamageTypesMenu roomId={roomId} damageTypes={damageTypes} />
              </div>
              <div className={`d-flex flex-column justify-content-start w-50 ${classes.damagedMaterials}`}>
                <DamagedMaterialsPlaceholder />
                <DamagedMaterialsModal />
              </div>
            </div>
          ) : (
            <div>
              <DamagedMaterialsView damageTypes={damageTypes} roomId={roomId} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const DamagedMaterialsMemo = memo(DamagedMaterials, areEqual);

export { DamagedMaterialsMemo as DamagedMaterials };
