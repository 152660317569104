import React, { memo } from 'react';

import { TabContentHeader } from 'Components/TabContentHeader';
import { areEqual } from 'Utils/equalityChecks';
import { ActionsCenter } from 'Containers/RocketScan';

interface Props {
  icon: string;
  name: string;
  propertyType: string;
  locationType?: string;
  jobNumber?: string;
  isCommercial?: boolean;
  isInaccessible?: boolean;
  scopeTotal?: number;
  scopeLabel?: string;
}

const HeaderContainer = ({
  icon,
  name,
  propertyType,
  locationType,
  jobNumber,
  isCommercial,
  isInaccessible,
  scopeTotal,
  scopeLabel,
}: Props) =>
  name && (
    <TabContentHeader
      icon={icon}
      name={name}
      isCommercial={isCommercial}
      isInaccessible={isInaccessible}
      jobNumber={jobNumber}
      actionsCenter={<ActionsCenter locationType={locationType} propertyType={propertyType} />}
      hasDivider
      scopeTotal={scopeTotal}
      scopeLabel={scopeLabel}
    />
  );

HeaderContainer.defaultProps = {
  locationType: 'roomsview',
  isCommercial: false,
  isInaccessible: undefined,
  jobNumber: undefined,
};
const HeaderContainerMemo = memo(HeaderContainer, areEqual);

export { HeaderContainerMemo as HeaderContainer };
