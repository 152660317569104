import React, { memo, ReactNode } from 'react';

import { areEqual } from 'Utils/equalityChecks';
import { DamageMaterial, SelectedItem } from 'Containers/RocketScope/types';
import { DamagedMaterialsContext, DamagedMaterialFunctions } from '.';

interface Props {
  roomId: number;
  projectId: number;
  locationId: number;
  roomDamagedMaterials: DamageMaterial[];
  roomScopeOfWorkItems: SelectedItem[];
  children: ReactNode;
}

const DamagedMaterialsProvider = ({
  children,
  roomId,
  roomDamagedMaterials,
  roomScopeOfWorkItems,
  projectId,
  locationId,
}: Props) => {
  const damagedMaterials = DamagedMaterialFunctions(
    roomId,
    projectId,
    locationId,
    roomDamagedMaterials,
    roomScopeOfWorkItems
  );

  return (
    <DamagedMaterialsContext.Provider value={{ ...damagedMaterials }}>{children}</DamagedMaterialsContext.Provider>
  );
};

const DamagedMaterialsProviderMemo = memo(DamagedMaterialsProvider, areEqual);

export { DamagedMaterialsProviderMemo as DamagedMaterialsProvider };
