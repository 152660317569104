export const customDamagedMaterialCreatedSelector = ({
  damagedMaterials: { customDamagedMaterialCreated: value = false },
}: any) => value;
export const creatingCustomDamagedMaterialSelector = ({
  damagedMaterials: { creatingCustomDamagedMaterial: value = false },
}: any) => value;

export const customDamagedMaterialUpdatedSelector = ({
  damagedMaterials: { customDamagedMaterialUpdated: value = false },
}: any) => value;
export const editingCustomDamagedMaterialsSelector = ({
  damagedMaterials: { editingCustomDamagedMaterial: value = false },
}: any) => value;

export const customDamagedMaterialDeletedSelector = ({
  damagedMaterials: { customDamagedMaterialDeleted: value = false },
}: any) => value;

export const projectScopeTotalSelector = (state, projectId) =>
  state.damagedMaterials.projectScopeTotals[projectId] || 0;
export const locationScopeTotalSelector = (state, locationId) =>
  state.damagedMaterials.locationScopeTotals[locationId] || 0;
export const roomScopeTotalSelector = (state, roomId) => state.damagedMaterials.roomScopeTotals[roomId] || 0;

// errors
export const nameErrorSelector = ({ damagedMaterials: { createCustomDamagedMaterialErrors } }: any) =>
  createCustomDamagedMaterialErrors?.name || [];
