import React, { memo, useCallback, useEffect } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { ChoosePropertyType } from 'Components/RocketScan';
import { Spinner } from 'Components/Spinner';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchingProjectSelector,
  projectAddressSelector,
  projectSelector,
  propertySelector,
  propertyTypesSelector,
  singleLocationRoomTypeSelector,
} from 'Containers/RocketScan/selectors';

import { locationTypesSelector, locationSelector } from 'Containers/RocketScan/MultiUnit/Locations/selectors';

import {
  createLocation,
  createSingleLocation,
  createExteriorLocation,
  createMultiUnitProperty,
  createMultiUnitPropertyFromTempProperty,
  createLocationForTempProperty,
} from 'Containers/RocketScan/MultiUnit/Locations/actions';
import { setFetchingProject } from 'Containers/RocketScan/actions';
import { selectHeaderIcon } from 'Utils/projectHelpers';
import { Exterior, Header, MultiUnit, SingleLocation, SingleUnit, RocketScanWrapper } from 'Containers/RocketScan';
import { getProjectScopeTotal } from 'Containers/RocketScan/RoomsView/DamagedMaterials/actions';
import { projectScopeTotalSelector } from 'Containers/RocketScan/RoomsView/DamagedMaterials/selectors';

// property types that should show location view
const multiUnitPropertyTypes = ['multiunit', 'commercial', 'school'];

// should this show the multiunit screen (locations view) or the singleunit screen (rooms view)?
const getDisplayMode = (name: string) => {
  if (multiUnitPropertyTypes.includes(name)) {
    return 'multiunit';
  }
  return 'singleunit';
};

const getIsInaccessible = (property: any, displayMode: string, location: any) => {
  // Do not show inaccessible tag if project is new and without properties
  // also don't show in multiunit screens
  if (property === undefined || displayMode === 'multiunit') {
    return false;
  }
  if (location?.id) {
    const { is_accessible: locationIsAccessible } = location;

    return !locationIsAccessible;
  }
  return false;
};

function RocketScanContainer() {
  const dispatch = useDispatch();

  // selectors
  const project = useSelector(projectSelector, areEqual);
  const scopeTotal = useSelector((state) => projectScopeTotalSelector(state, project?.id || 0), areEqual);
  const projectAddress = useSelector(projectAddressSelector, areEqual);
  const property = useSelector(propertySelector, areEqual);
  const propertyTypes = useSelector(propertyTypesSelector, areEqual);
  const locationTypes = useSelector(locationTypesSelector, areEqual);
  const fetching = useSelector(fetchingProjectSelector, areEqual);
  const singleLocationRoomType = useSelector(singleLocationRoomTypeSelector, areEqual);
  const location = useSelector(locationSelector, areEqual);

  // set options according to property type
  const propertyType = property?.name || '';
  const isTempPropertyType = propertyType === 'temp';
  const displayMode = getDisplayMode(propertyType);

  const isInaccessible = getIsInaccessible(property, displayMode, location);

  useEffect(() => {
    if (project?.id) {
      dispatch(getProjectScopeTotal(project.id));
    }
  }, [project]);

  // useEffect

  // creating a single unit
  const onSingleUnitTileClick = useCallback(() => {
    if (locationTypes.length > 0 && propertyTypes.length > 0) {
      // enable main spinner. this will automatically disabled on project details endpoint
      dispatch(setFetchingProject(true));

      // variables
      const propertyTypeId = propertyTypes.find((type: any) => type.name === 'singleunit')?.id;
      const locationTypeId = locationTypes.find((type: any) => type.name === 'unit')?.id;
      const projectId = project?.id;

      // api call
      if (isTempPropertyType) {
        dispatch(
          createLocationForTempProperty(
            propertyTypeId,
            {
              location_type_id: locationTypeId,
              name: projectAddress,
              floor_number: 0, // using 0 as default for single units
            },
            property.id
          )
        );
      } else {
        dispatch(
          createLocation(projectId, propertyTypeId, {
            location_type_id: locationTypeId,
            name: projectAddress,
            floor_number: 0, // using 0 as default for single units
          })
        );
      }
    } else {
      alert('Something went wrong. Please try again later!');
    }
  }, [project, property, isTempPropertyType, locationTypes, propertyTypes, projectAddress]);

  // creating a single location
  const onSingleLocationTileClick = useCallback(() => {
    if (locationTypes.length > 0 && propertyTypes.length > 0) {
      // enable main spinner. this will automatically disabled on project details endpoint
      dispatch(setFetchingProject(true));

      // variables
      const propertyTypeId = propertyTypes.find((type: any) => type.name === 'singlelocation')?.id;
      const locationTypeId = locationTypes.find((type: any) => type.name === 'unit')?.id;
      const projectId = project?.id;
      const roomTypeId = singleLocationRoomType?.id;

      // api call
      if (isTempPropertyType) {
        dispatch(
          createLocationForTempProperty(
            propertyTypeId,
            {
              location_type_id: locationTypeId,
              name: projectAddress,
              floor_number: 0, // using 0 as default for single units
            },
            property.id,
            { propertyName: 'single location', roomTypeId }
          )
        );
      } else {
        dispatch(
          createSingleLocation(
            projectId,
            propertyTypeId,
            {
              location_type_id: locationTypeId,
              name: projectAddress,
              floor_number: 0, // using 0 as default for single units
            },
            roomTypeId
          )
        );
      }
    } else {
      alert('Something went wrong. Please try again later!');
    }
  }, [project, property, isTempPropertyType, locationTypes, propertyTypes, projectAddress, singleLocationRoomType]);

  // creating a exterior location
  const onExteriorTileClick = useCallback(() => {
    if (locationTypes.length > 0 && propertyTypes.length > 0) {
      // enable main spinner. this will automatically disabled on project details endpoint
      dispatch(setFetchingProject(true));

      // variables
      const propertyTypeId = propertyTypes.find((type: any) => type.name === 'exterior')?.id;
      const locationTypeId = locationTypes.find((type: any) => type.name === 'unit')?.id;
      const projectId = project?.id;

      // api call
      if (isTempPropertyType) {
        dispatch(
          createLocationForTempProperty(
            propertyTypeId,
            {
              location_type_id: locationTypeId,
              name: projectAddress,
              floor_number: 0, // using 0 as default for single units
            },
            property.id,
            { propertyName: 'exterior' }
          )
        );
      } else {
        dispatch(
          createExteriorLocation(projectId, propertyTypeId, {
            location_type_id: locationTypeId,
            name: projectAddress,
            floor_number: 0, // using 0 as default for single units
          })
        );
      }
    } else {
      alert('Something went wrong. Please try again later!');
    }
  }, [project, property, isTempPropertyType, locationTypes, propertyTypes, projectAddress]);

  const onMultiUnitTileClick = useCallback(() => {
    if (locationTypes.length > 0 && propertyTypes.length > 0) {
      const projectId = project?.id;
      // enable main spinner. this will automatically disabled on project details endpoint
      dispatch(setFetchingProject(true));

      // variables
      const propertyTypeId = propertyTypes.find((type: any) => type.name === 'multiunit')?.id;

      // api call
      if (isTempPropertyType) {
        dispatch(createMultiUnitPropertyFromTempProperty(property.id, propertyTypeId, 'multiunit'));
      } else {
        dispatch(createMultiUnitProperty(projectId, propertyTypeId, 'multiunit'));
      }
    } else {
      alert('Something went wrong. Please try again later!');
    }
  }, [project, locationTypes, propertyTypes, property, isTempPropertyType]);

  const onCommercialTileClick = useCallback(() => {
    if (locationTypes.length > 0 && propertyTypes.length > 0) {
      const projectId = project?.id;
      // enable main spinner. this will automatically disabled on project details endpoint
      dispatch(setFetchingProject(true));

      // variables
      const propertyTypeId = propertyTypes.find((type: any) => type.name === 'commercial')?.id;

      // api call
      if (isTempPropertyType) {
        dispatch(createMultiUnitPropertyFromTempProperty(property.id, propertyTypeId, 'commercial'));
      } else {
        dispatch(createMultiUnitProperty(projectId, propertyTypeId, 'commercial'));
      }
    } else {
      alert('Something went wrong. Please try again later!');
    }
  }, [project, locationTypes, propertyTypes, property, isTempPropertyType]);

  const onSchoolTileClick = useCallback(() => {
    if (locationTypes.length > 0 && propertyTypes.length > 0) {
      const projectId = project?.id;
      // enable main spinner. this will automatically disabled on project details endpoint
      dispatch(setFetchingProject(true));

      // variables
      const propertyTypeId = propertyTypes.find((type: any) => type.name === 'school')?.id;

      // api call
      if (isTempPropertyType) {
        dispatch(createMultiUnitPropertyFromTempProperty(property.id, propertyTypeId, 'school'));
      } else {
        dispatch(createMultiUnitProperty(projectId, propertyTypeId, 'school'));
      }
    } else {
      alert('Something went wrong. Please try again later!');
    }
  }, [project, locationTypes, propertyTypes, property, isTempPropertyType]);

  return (
    <RocketScanWrapper>
      <Spinner loading={fetching} />
      {!fetching && (
        <Header
          icon={selectHeaderIcon(propertyType)}
          name={projectAddress}
          isCommercial={propertyType === 'singleunit'}
          isInaccessible={isInaccessible}
          propertyType={displayMode}
          locationType={displayMode === 'multiunit' ? 'locationsview' : 'roomsview'}
          jobNumber={project?.uid}
          scopeTotal={scopeTotal}
          scopeLabel="Project Total"
        />
      )}
      {!fetching && (!property?.id || isTempPropertyType) && (
        <ChoosePropertyType
          onSingleUnitTileClick={onSingleUnitTileClick}
          onSingleLocationTileClick={onSingleLocationTileClick}
          onMultiUnitTileClick={onMultiUnitTileClick}
          onExteriorTileClick={onExteriorTileClick}
          onCommercialTileClick={onCommercialTileClick}
          onSchoolTileClick={onSchoolTileClick}
        />
      )}
      {!fetching && propertyType === 'singleunit' && <SingleUnit />}
      {!fetching && propertyType === 'multiunit' && <MultiUnit />}
      {!fetching && propertyType === 'singlelocation' && <SingleLocation />}
      {!fetching && propertyType === 'exterior' && <Exterior />}
      {!fetching && propertyType === 'commercial' && <MultiUnit isCommercialProperty />}
      {!fetching && propertyType === 'school' && <MultiUnit />}
    </RocketScanWrapper>
  );
}

const RocketScanContainerMemo = memo(RocketScanContainer, areEqual);

export { RocketScanContainerMemo as RocketScan };
