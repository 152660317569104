import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  damagedMaterialsSelector,
  damageTypesSelector,
  projectHasNewDamageTypesSelector,
  scopeActionTypesSelector,
  unitOfMeasurementTypesSelector,
} from 'Containers/RocketScan/selectors';
import { areEqual } from 'Utils/equalityChecks';
import { addOrRemoveFromArray } from 'Utils/helpers';
import {
  addScopeSelectedItems,
  getRoomDamageMaterials,
  getRoomScopeOfWorkItems,
  syncRoomDamagedMaterials,
  syncScopeSelectedItems,
  updateMaterialScopeOfWork,
} from 'Containers/RocketScan/RoomsView/DamagedMaterials/actions';
import { scopeSheetsSelector } from 'Containers/RocketScope/selectors';
import { DamageMaterial, DamageType, SelectedItem } from 'Containers/RocketScope/types';
import { userFeatureFlagsSelector } from 'Containers/User/selector';

export const DamagedMaterialsContext = createContext({});

export enum EditMode {
  NoEditing,
  Editing,
  Adding,
}

export const DamagedMaterialFunctions = (
  roomId: number,
  projectId: number,
  locationId: number,
  roomDamagedMaterials: DamageMaterial[],
  roomScopeWorkItems: SelectedItem[]
) => {
  const dispatch = useDispatch();

  // local state
  const [selectedScopeSheet, setSelectedScopeSheet] = useState<number | null>(null);
  const [editMode, setEditMode] = useState(EditMode.NoEditing);
  const [damagesCount, setDamagesCount] = useState(0);

  // These are the old damaged materials...
  const [selectedDamagedType, setSelectedDamagedType] = useState(null);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [prevSelectedMaterials, setPrevSelectedMaterials] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isMaterialsModalOpen, setIsMaterialsModalOpen] = useState(false);

  // This is the new scope of work items
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const [addedSelectedItems, setAddedSelectedItems] = useState<SelectedItem[]>([]);
  const [prevSelectedItems, setPrevSelectedItems] = useState<SelectedItem[]>([]);
  const [isScopeAddModalOpen, setIsScopeAddModalOpen] = useState(false);
  const [removedItems, setRemovedItems] = useState<SelectedItem[]>([]);
  const [damageTypes, setDamageTypes] = useState<DamageType[]>([]);
  const [isScopeOfWorkEditModal, setIsScopeOfWorkEditModal] = useState(false);

  // selectors
  const scopeSheets = useSelector(scopeSheetsSelector, areEqual);
  const damageTypesUnfiltered: DamageType[] = useSelector(damageTypesSelector, areEqual);
  const damagedMaterials = useSelector(damagedMaterialsSelector, areEqual);
  const unitOfMeasurementTypes = useSelector(unitOfMeasurementTypesSelector, areEqual);
  const scopeActionTypes = useSelector(scopeActionTypesSelector, areEqual);
  const useNewDamageTypes = useSelector(projectHasNewDamageTypesSelector, areEqual);
  const { rocketScope } = useSelector(userFeatureFlagsSelector, areEqual);

  useEffect(() => {
    setSelectedMaterials(roomDamagedMaterials);
    setSelectedItems(roomScopeWorkItems);
    setPrevSelectedItems(roomScopeWorkItems);
    setDamagesCount(roomDamagedMaterials.length + roomScopeWorkItems.length);
  }, []);

  useEffect(() => {
    if (damageTypesUnfiltered) {
      if (useNewDamageTypes) {
        setDamageTypes(damageTypesUnfiltered.filter((type) => type.version === 'common' || type.version === 'new'));
      } else {
        setDamageTypes(damageTypesUnfiltered.filter((type) => type.version === 'common' || type.version === 'old'));
      }
    }
  }, [damageTypesUnfiltered, useNewDamageTypes]);

  // helpers
  const refetchDamagedMaterialsAfterUpdate = useCallback(async () => {
    const response: any = await dispatch(getRoomDamageMaterials(roomId));

    if (response?.data) {
      setSelectedMaterials(response.data);
      setPrevSelectedMaterials(response.data);
      setDamagesCount(roomDamagedMaterials.length);
    }
  }, []);

  const updateDamagedMaterialsSelection = useCallback(async () => {
    // prepare ids and make the api call
    const materialIds = selectedMaterials.map((material: any) => material.id.toString());

    // make the api call only if length is different, this means user has change his selection
    if (roomDamagedMaterials.length !== materialIds.length) {
      dispatch(syncRoomDamagedMaterials(roomId, { damage_material_ids: materialIds }));
    }
  }, [selectedMaterials, roomDamagedMaterials]);

  const setSelectedItemQuantity = useCallback((selectedItem: SelectedItem, quantity: number) => {
    setSelectedItems((previousSelectedItems) => {
      const index = previousSelectedItems.findIndex((item) => item.id === selectedItem.id);
      if (index !== -1) {
        previousSelectedItems[index].quantity = quantity;
      }
      return previousSelectedItems;
    });
  }, []);

  const setAddItemQuantity = useCallback((selectedItem: SelectedItem, quantity: number) => {
    setAddedSelectedItems((prevAddedItems) => {
      const index = prevAddedItems.findIndex((item) => item.id === selectedItem.id);
      if (index !== -1) {
        prevAddedItems[index].quantity = quantity;
      }
      return prevAddedItems;
    });
  }, []);

  // callbacks
  //

  const onSheetSelectClick = useCallback(
    (id: number) => {
      setSelectedScopeSheet(id);
      setIsScopeAddModalOpen(true);
    },
    [selectedItems]
  );

  const onDamagedListItemClick = useCallback(
    (material: any) => {
      setSelectedMaterials(addOrRemoveFromArray(selectedMaterials, material));
    },
    [selectedMaterials]
  );

  const updateScope = useCallback((materialId: number, requestData: any) => {
    dispatch(updateMaterialScopeOfWork(roomId, materialId, requestData));
  }, []);

  const onClickEditButton = useCallback(() => {
    setEditMode((prev) => {
      if (prev === EditMode.NoEditing) {
        return EditMode.Editing;
      }
      return EditMode.NoEditing;
    });
    if (rocketScope) {
      setSelectedScopeSheet(null);
      setPrevSelectedItems(selectedItems);
      setIsScopeOfWorkEditModal(true);
    } else {
      setIsUploading(false);
      setSelectedDamagedType(null);
      (async function updateDamageMaterials() {
        await refetchDamagedMaterialsAfterUpdate();
      })();
    }
  }, [editMode, selectedItems, refetchDamagedMaterialsAfterUpdate]);

  const onClickAddButton = useCallback(() => {
    setEditMode((prev) => {
      if (prev === EditMode.Adding) {
        return EditMode.NoEditing;
      }
      return EditMode.Adding;
    });
  }, [editMode]);

  const onClickAddScopeSaveButton = useCallback(async () => {
    setIsScopeAddModalOpen(false);
    setDamagesCount(selectedMaterials.length + addedSelectedItems.length + selectedItems.length);
    await dispatch(addScopeSelectedItems(roomId, projectId, locationId, addedSelectedItems));
    const response: any = await dispatch(getRoomScopeOfWorkItems(roomId));
    if (response?.data) {
      setSelectedItems(() => {
        const newSelectedItems = response.data.map((item) => ({
          id: item.id,
          sheetId: item.sheet_id,
          quantity: item.quantity,
          category: item.category,
          codePart1: item.code_part_1 || '',
          codePart2: item.code_part_2 || '',
          description: item.description,
          unit: item.unit,
          rate: item.rate,
        }));
        setDamagesCount(newSelectedItems.length + selectedMaterials.length);
        return newSelectedItems;
      });
    }
    setAddedSelectedItems([]);
  }, [setSelectedItems, selectedMaterials, roomId, addedSelectedItems, selectedItems]);

  const onClickScopeEditSaveButton = useCallback(async () => {
    setIsScopeOfWorkEditModal(false);
    setEditMode(EditMode.NoEditing);
    await dispatch(syncScopeSelectedItems(roomId, projectId, locationId, selectedItems, removedItems));
    setRemovedItems([]);
    setPrevSelectedItems(selectedItems);
  }, [roomId, selectedItems, removedItems]);

  const onClickScopeEditCancelButton = useCallback(() => {
    setIsScopeOfWorkEditModal(false);
    setEditMode(EditMode.NoEditing);
    setSelectedItems(prevSelectedItems);
    setRemovedItems([]);
  }, [prevSelectedItems]);

  const onCLickAddScopeCancelButton = useCallback(() => {
    setIsScopeAddModalOpen(false);
  }, []);

  const updateSelectedItems = useCallback((newSelectedItems: SelectedItem[]) => {
    setAddedSelectedItems(newSelectedItems);
  }, []);

  const removeSelectedItem = useCallback((selectedItem: SelectedItem) => {
    setSelectedItems((previousSelectedItems) => previousSelectedItems.filter((item) => item.id !== selectedItem.id));
    setRemovedItems((prevRemovedItems) =>
      prevRemovedItems.includes(selectedItem) ? prevRemovedItems : [...prevRemovedItems, selectedItem]
    );
  }, []);

  useEffect(() => {
    setDamagesCount((selectedMaterials?.length || 0) + (selectedItems?.length || 0));
  }, [selectedMaterials, selectedItems]);

  // Old Damage Materials Callbacks
  const onDamagedTypeTileClick = useCallback(
    (id: number) => {
      setSelectedDamagedType(id);
      setIsMaterialsModalOpen(true);
      // save the current selected materials so it can be restored
      setPrevSelectedMaterials(selectedMaterials);
    },
    [selectedMaterials]
  );

  // for when user clicks the save button on the damaged materials modal
  const onClickSaveButton = useCallback(async () => {
    await updateDamagedMaterialsSelection();
    setIsUploading(true);
    setIsMaterialsModalOpen(false);
    setPrevSelectedMaterials(roomDamagedMaterials);

    // update
    setTimeout(async () => {
      await refetchDamagedMaterialsAfterUpdate();
    }, 2000);
  }, [updateDamagedMaterialsSelection, roomDamagedMaterials]);

  // for when user clicks the cancel button on the damaged materials modal
  const onClickCancelButton = useCallback(() => {
    setIsMaterialsModalOpen(false);
    setSelectedMaterials(prevSelectedMaterials);
  }, [prevSelectedMaterials]);

  return {
    selectedDamagedType,
    selectedScopeSheet,
    roomId,
    editMode,
    damagesCount,
    scopeSheets,
    damageTypes,
    damagedMaterials,
    unitOfMeasurementTypes,
    scopeActionTypes,
    onClickEditButton,
    onClickAddButton,
    onDamagedListItemClick,
    onDamagedTypeTileClick,
    updateScope,
    onSheetSelectClick,
    selectedItems,
    setSelectedItems,
    removeSelectedItem,
    setSelectedItemQuantity,
    updateSelectedItems,
    isScopeOfWorkEditModal,
    onClickScopeEditSaveButton,
    onClickScopeEditCancelButton,
    onClickAddScopeSaveButton,
    onCLickAddScopeCancelButton,
    addedSelectedItems,
    isScopeAddModalOpen,
    setAddItemQuantity,

    // DamagedMaterialsModal
    isUploading,
    setIsUploading,
    selectedMaterials, // This is the old damaged materials
    onClickSaveButton,
    onClickCancelButton,
    isMaterialsModalOpen,
  };
};

export const useDamagedMaterialFunctions = () => useContext(DamagedMaterialsContext);
