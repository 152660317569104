import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { EditButton } from 'Components/Button/EditButton';

import { Icon } from 'Components/Icons';

import { useLocationsViewFunctions } from 'Context/Project/LocationsView';

import { userFeatureFlagsSelector } from 'Containers/User/selector';

import { locationScopeTotalSelector } from 'Containers/RocketScan/RoomsView/DamagedMaterials/selectors';
import { getLocationScopeTotal } from 'Containers/RocketScan/RoomsView/DamagedMaterials/actions';
import classes from './multiUnitLocationHeader.module.css';

interface Props {
  location: any;
  onClickLocationName: (e: any) => void;
}

const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

function MultiUnitLocationHeader({ location, onClickLocationName }: Props) {
  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);

  const { setLocationInfo, setIsOpenDeleteLocationModal }: any = useLocationsViewFunctions();
  const { id: locationId, name, floor_number: floor, photos_count: photosCount, rooms_count: roomsCount } = location;

  const { hideDeleteButton } = useSelector(userFeatureFlagsSelector, areEqual);

  const scopeTotal = useSelector((state) => locationScopeTotalSelector(state, location?.id || 0), areEqual);

  const onEditButtonClick = useCallback(() => {
    setEditable(!editable);
  }, [editable]);

  const onDeleteIconClick = useCallback(
    (e: any) => {
      e.preventDefault();
      if (locationId) {
        setLocationInfo(locationId, name, floor, photosCount, roomsCount);
        setIsOpenDeleteLocationModal(true);
      }
    },
    [locationId]
  );

  useEffect(() => {
    if (location?.id) {
      dispatch(getLocationScopeTotal(location.id));
    }
  }, [location]);

  return (
    <h6 className={classes.locationHeader}>
      <span className={classes.editBar}>
        {editable ? (
          <span>{name}</span>
        ) : (
          <span role="button" tabIndex={0} onClick={onClickLocationName} onKeyUp={onClickLocationName}>
            {name}
          </span>
        )}
        {scopeTotal > 0 && <div className={classes.scopeTotal}>Unit Total: {currencyFormat.format(scopeTotal)}</div>}
        {!hideDeleteButton && editable && (
          <Icon className={classes.deleteIcon} type="trashsm" onClick={onDeleteIconClick} />
        )}
      </span>
      <EditButton editable={editable} onClick={onEditButtonClick} />
    </h6>
  );
}

const MultiUnitLocationHeaderMemo = memo(MultiUnitLocationHeader, areEqual);

export { MultiUnitLocationHeaderMemo as MultiUnitLocationHeader };
